import * as React from "react"
import {graphql} from "gatsby";
import {CollectionProductList, ShopifyProductList} from "../types/data";
import CircularCarousel from "../components/Carousel/CircularCarousel";
import {css, useTheme} from "@emotion/react";
import Toggle from "../components/Toggle/Toggle";
import {mobileBreakpoint} from "../constants";
import ProductGrid from "../components/ProductGrid/ProductGrid";
import HomeTitle from "../components/HomeTitle/HomeTitle";
import {topBarHeight} from "../utils";
import {useGlobalState} from "../state";
import Responsive from "../components/Responsive/Responsive";
import MobileCarousel from "../components/Carousel/MobileCarousel";
import type {HeadProps} from "gatsby"
import {SEO} from "../components/SEO/SEO";


const CollectionTemplate = ({data}: { data: CollectionProductList }) => {
    const theme = useTheme();
    const [listView, setListView] = useGlobalState('listView')
    return (
        <main>
            <div css={css`
              position: absolute;
              right: ${theme.margin.main}px;
              top: ${topBarHeight(theme) + theme.margin.main}px;
              z-index: 10000;

              @media (max-width: ${mobileBreakpoint}px) {
                right: unset;
                left: ${theme.margin.main}px;
                top: 50vh;
                transform: rotate(90deg);
                transform-origin: top;
              }
            `}>
                <Toggle active={listView} onToggle={setListView}/>
            </div>
            <HomeTitle/>
            {listView
                ? <ProductGrid items={data.shopifyCollection.products}></ProductGrid>
                : <Responsive
                    desktop={<CircularCarousel items={data.shopifyCollection.products}/>}
                    mobile={<MobileCarousel items={data.shopifyCollection.products}/>}/>
            }
        </main>
    )
}
export const pageQuery = graphql`
    query CollectionProductList($handle: String!) {
        shopifyCollection(handle: {eq: $handle}) {
            title
            products {
                title
                vendor
                handle
                id
                variants {
                    id
                    title
                    image {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`


export default CollectionTemplate

export const Head = ({data, pageContext}: {
    data: {
        shopifyCollection: {
            title: string
        }
    },
    pageContext: {
        isCurrentCollection: boolean
    }
}) => {
    return (<SEO title={pageContext.isCurrentCollection ? undefined : data?.shopifyCollection?.title}/>)
}

