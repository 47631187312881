import React, {useEffect, useState} from 'react'
import {css, useTheme} from "@emotion/react";
import {topBarHeight} from "../../utils";
import {useGlobalState} from "../../state";
import {act} from "react-dom/test-utils";

const widthFactor = 7.548

const HomeTitle = () => {
  const theme = useTheme();
  const [activated, setActivated] = useGlobalState('activated');
  const [transitionsFrozen, setTransitionsFrozen] = useState(false)
  const activatedTop = `${topBarHeight(theme) + theme.margin.main}px`;
  const activatedWidth = theme.fontSize.big * widthFactor;
  const introHeight = `calc(100vw / ${widthFactor})`
  const introTop = `calc(100vh / 2 - (100vw / ${widthFactor}) / 2)`
  const activatedLeft = `calc(100vw / 2 - ${activatedWidth / 2}px)`

  useEffect(() => {
    const timeOut = window.setTimeout(() => {
      setActivated(true);
    }, 200)
    return () => {
      window.clearTimeout(timeOut)
    }
  })

  useEffect(() => {
    if (activated && !transitionsFrozen) {
      const timeOut = window.setTimeout(() => {
        setTransitionsFrozen(true);
      }, 1500)
      return () => {
        window.clearTimeout(timeOut)
      }
    }
  }, [activated])

  return (
    <>
      <svg
        css={css`
          position: fixed;
          top: ${activated ? activatedTop : introTop};
          z-index: 99000001;
          left: ${activated ? activatedLeft : 0};
          height: ${activated ? `${theme.fontSize.big}px` : introHeight};
          width: ${activated ? `${activatedWidth}px` : '100vw'};
          transition: ${transitionsFrozen ? 'none' : '1s ease'};
          pointer-events: none;
          transform: ${activated ? "scale(1)" : "scale(0.98)"};
          user-select: none;
        `}
        viewBox="8.999 17.742 111.251 14.739" xmlns="http://www.w3.org/2000/svg">
        <text fontFamily={theme.fontFamily} fontSize={17.3} fill={theme.color.secondary} x="8.999" y="31.299">
          TYPE O'CLOCK
        </text>
      </svg>
      <button css={css`
        pointer-events: ${activated ? "none" : "all"};
        user-select: none;
        background: white;
        transition: all 1s ease;
        transition-delay: .5s;
        box-shadow: ${theme.boxShadow};
        height: 100vh;
        width: 100vw;
        padding: 0;
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99000000;
        border: none;
        color: white;
        font-size: 1px;
        opacity: ${activated ? 0 : 1};
        cursor: pointer;
      `}
              onClick={() => {
                setActivated(true)
              }}
      >
        Click to enter site
      </button>
    </>
  )
}

export default HomeTitle;
