import React, {ReactNode} from 'react'
import {useWindowSize} from "rooks";
import {mobileBreakpoint} from "../../constants";

interface Props {
  desktop: ReactNode,
  mobile: ReactNode
}

const Responsive = ({desktop, mobile}: Props) => {
  const {innerWidth, innerHeight} = useWindowSize();
  if (innerWidth === null || innerHeight === null) {
    return (<noscript>
      JavaScript is required to display some components of this website, please enable JavaScript in your browser.
    </noscript>)
  }
  if (innerWidth > mobileBreakpoint) return <>{desktop}</>
  return <>{mobile}</>
}

export default Responsive;
