import {createGlobalState} from 'react-hooks-global-state';

const initialState = {
  activated: false,
  listView: false,
  carouselIndex: 0,
  mobileCarouselIndex: 0
};
const {useGlobalState} = createGlobalState(initialState);
export {
  useGlobalState
}
