import React, {useState} from 'react'
import {css, useTheme} from "@emotion/react";
import {animated, useSpring} from 'react-spring';

interface Props {
  active: boolean,
  onToggle: (val: boolean) => void
}

const HOVER_OFFSET = 10

const Toggle = ({active, onToggle}: Props) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  let switchPosition = 19.988;

  if (active) {
    switchPosition = 66.128
    if (hover) {
      switchPosition -= HOVER_OFFSET
    }
  } else if (hover) {
    switchPosition += HOVER_OFFSET
  }

  const circleProps = useSpring({
    cx: `${switchPosition}`
  })
  return (
    <svg
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      onClick={() => {
        onToggle(!active)
        setHover(false);
      }}
      css={css`
        cursor: pointer;
      `}
      width="84.571" height="38.386" viewBox="0.4 -0.823 84.82 38.598" xmlns="http://www.w3.org/2000/svg">
      <path
        vectorEffect="non-scaling-stroke"
        d="M 84.128 18.483 C 84.129 8.435 75.984 0.29 65.935 0.29 L 20.181 0.29 C 10.133 0.29 1.988 8.435 1.988 18.483 C 1.988 28.532 10.133 36.677 20.181 36.676 L 65.935 36.676 C 75.984 36.677 84.129 28.532 84.128 18.483 Z"
        fill="rgba(255,255,255, 0.6)" stroke="#2b2e34" strokeLinecap="round" strokeWidth="2"/>
      <animated.circle
        vectorEffect="non-scaling-stroke"
        fill="white" strokeWidth={2} cy="18.29" r="18" stroke={theme.color.secondary}
        {...circleProps}
      />
    </svg>
  )
}

export default Toggle;
