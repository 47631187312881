import React, {useEffect, useState} from 'react'
import {ShopifyProductList, ShopifyProductNode} from "../../types/data";
import {css} from "@emotion/react";
import ProductGridItem from "./ProductGridItem";
import {populateMin, spreadVariants} from "../../utils";
import {mobileBreakpoint} from "../../constants";

interface Props {

}

const ProductGrid = ({
                       items
                     }: { items: ShopifyProductNode[] }) => {
  const products = spreadVariants(items)
  const [placeholderTiles, setPlaceholderTiles] = useState(0);

  useEffect(() => {
    let numPlaceholders = 0
    if(window.innerWidth > mobileBreakpoint){
      numPlaceholders = 4 - (products.length % 4)
      if(numPlaceholders === 4) numPlaceholders = 0;
    }else{
      numPlaceholders = 2 - (products.length % 2)
      if(numPlaceholders === 2) numPlaceholders = 0;
    }
    setPlaceholderTiles(numPlaceholders);
  }, [products])

  const placeholderElements = []
  for (let i =0; i < placeholderTiles; i++){
    placeholderElements.push(<ProductGridItem key={products.length + i} index={products.length + i}/>)
  }

  return (
    <div css={css`
      height: 100%;
      width: 100%;
      overflow: auto;
      user-select: none;
    `}>
      <div css={css`
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-auto-flow: row;

        @media (max-width: ${mobileBreakpoint}px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      `}>
        {products.map((n, idx) => (<ProductGridItem index={idx} item={n} key={idx}/>))}
        {placeholderElements}
      </div>
    </div>
  )
}

export default ProductGrid;
