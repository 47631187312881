import React, {useState} from 'react'
import {DisplayProduct, ShopifyProductNode} from "../../types/data";
import {css, useTheme} from "@emotion/react";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {mobileBreakpoint} from "../../constants";

// Item is optional, passing no item will render a placeholder tile
const ProductGridItem = ({item, index}: { item?: DisplayProduct, index: number }) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      css={css`
        padding: ${theme.margin.main}px;
        box-shadow: ${theme.boxShadow};
        position: relative;
        display: flex;
        flex-direction: column;

        @media (min-width: ${mobileBreakpoint}px) {
          height: calc((100vh - ${theme.fontSize.main}px - ${theme.margin.main * 2}px) / 2);
        }


        ${index < 2 && css`
          @media (max-width: ${mobileBreakpoint}px) {
            padding-top: ${theme.fontSize.big}px;
          }
        `}

      `}>

      {item &&
        <Link
          css={css`
            flex-grow: 2;
            display: flex;
            flex-direction: column;
          `}
          to={`/product/${item.handle}`}>
          <div css={css`
            flex-grow: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}>
            <GatsbyImage
              draggable={false}
              css={css`
                width: 100%;
              `}
              alt="" image={item.imageData}/>
          </div>
          <div css={css`
            text-align: center;
          `}>
            {item.title}
          </div>
        </Link>
      }


      {/* Hover indicator */}
      {
        item && hover &&
        <div css={css`
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          user-select: none;
          border: 2px solid black;
          animation: appear 1s ease;

          @keyframes appear {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}>

        </div>
      }

    </div>
  )
}

export default ProductGridItem;
